import React from "react"

import { faUserEdit, faUsersViewfinder, faWaveformLines } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CardContent, Avatar, Grid } from "@mui/material"
import { UserRole } from "models/UserManagement"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { StyleTypography, StyleCssBaseline, StyleCard } from "../../components/StyleMaterialUI"
import { ADMIN_ROLES, ALL_ROLES } from "../../utils/roles"
import useRolesCanAccess from "../../utils/useRolesCanAccess"

const Home = () => {
  const { t } = useTranslation(["scouting", "data-collection", "playdata"])

  const taiyoroAccess = useRolesCanAccess(ALL_ROLES)

  const adminAccess = useRolesCanAccess(ADMIN_ROLES)

  const scoutingAndPlayDataAccess = useRolesCanAccess([...ADMIN_ROLES, UserRole.PRODUCER])

  const futarokuAccess = useRolesCanAccess(ALL_ROLES)

  return (
    <React.Fragment>
      <StyleCssBaseline />
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="calc(100% - 24px)"
      >
        <Grid
          item
          xs={8}
          style={{ width: "100%", maxWidth: "1024px", padding: "12px" }}
        >
          <Grid
            container
            spacing={3}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={12}
            >
              <StyleTypography
                align="center"
                variant="h3"
                mb="28px"
              >
                PlayBrain CMS
              </StyleTypography>
            </Grid>
            <Grid
              container
              spacing={3}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              {taiyoroAccess && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                >
                  <Link
                    to="/taiyoro/event/list"
                    style={{ textDecoration: "none" }}
                  >
                    <StyleCard style={{ maxWidth: "320px", padding: "0" }}>
                      <CardContent>
                        <Avatar
                          style={{ margin: "0 auto" }}
                          src="/images/taiyoro-logo.png"
                        ></Avatar>
                        <StyleTypography
                          align="center"
                          variant="h5"
                          mt="24px"
                        >
                          TAIYORO
                        </StyleTypography>
                      </CardContent>
                    </StyleCard>
                  </Link>
                </Grid>
              )}
              {adminAccess && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                >
                  <Link
                    to="/users/PLAYBRAIN"
                    style={{ textDecoration: "none" }}
                  >
                    <StyleCard style={{ maxWidth: "320px", padding: "0" }}>
                      <CardContent>
                        <Avatar style={{ margin: "0 auto" }}>
                          <FontAwesomeIcon icon={faUserEdit} />
                        </Avatar>
                        <StyleTypography
                          align="center"
                          variant="h5"
                          mt="24px"
                        >
                          User Management
                        </StyleTypography>
                      </CardContent>
                    </StyleCard>
                  </Link>
                </Grid>
              )}
              {scoutingAndPlayDataAccess && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                >
                  <Link
                    to="/scouting"
                    style={{ textDecoration: "none" }}
                  >
                    <StyleCard style={{ maxWidth: "320px", padding: "0" }}>
                      <CardContent>
                        <Avatar style={{ margin: "0 auto" }}>
                          <FontAwesomeIcon icon={faUsersViewfinder} />
                        </Avatar>
                        <StyleTypography
                          align="center"
                          variant="h5"
                          mt="24px"
                        >
                          {t("scouting:pageTitle")}
                        </StyleTypography>
                      </CardContent>
                    </StyleCard>
                  </Link>
                </Grid>
              )}
              {adminAccess && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                >
                  <Link
                    to="/data-collection/suggestions"
                    style={{ textDecoration: "none" }}
                  >
                    <StyleCard style={{ maxWidth: "320px", padding: "0" }}>
                      <CardContent>
                        <Avatar style={{ margin: "0 auto" }}>
                          <FontAwesomeIcon icon={faWaveformLines} />
                        </Avatar>
                        <StyleTypography
                          align="center"
                          variant="h5"
                          mt="24px"
                        >
                          {t("data-collection:pageTitle")}
                        </StyleTypography>
                      </CardContent>
                    </StyleCard>
                  </Link>
                </Grid>
              )}
              {scoutingAndPlayDataAccess && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                >
                  <Link
                    to="/playdata"
                    style={{ textDecoration: "none" }}
                  >
                    <StyleCard style={{ maxWidth: "320px", padding: "0" }}>
                      <CardContent>
                        <img
                          src="/images/playdata-logo.png"
                          height="40px"
                          alt="PLAYDATA"
                        />
                        <StyleTypography
                          align="center"
                          variant="h5"
                          mt="24px"
                        >
                          {t("playdata:pageTitle")}
                        </StyleTypography>
                      </CardContent>
                    </StyleCard>
                  </Link>
                </Grid>
              )}
              {futarokuAccess && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                >
                  <Link
                    to="/futaroku/ljl-supporters"
                    style={{ textDecoration: "none" }}
                  >
                    <StyleCard style={{ maxWidth: "320px", padding: "0" }}>
                      <CardContent>
                        <img
                          src="/images/store_futaroku_square.svg"
                          height="40px"
                          alt="Futaroku"
                          style={{ backgroundColor: 'rgba(249, 236, 30, 1)' }}
                        />
                        <StyleTypography
                          align="center"
                          variant="h5"
                          mt="24px"
                        >
                          Futaroku
                        </StyleTypography>
                      </CardContent>
                    </StyleCard>
                  </Link>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Home
