import React from "react"

const Futaroku = () => {
    return (
        <>
            Futaroku Home Page
        </>
    )
}

export default Futaroku
