import { ID_TOKEN_KEY } from "utils/graphql"

const fetchEndpoint = "https://webhook.dev.futaroku.gg/api/ljl-supporters"

export type LineItem = {
    title: string
    vendor: string
    properties: Array<{ name: string, value: string }> | undefined
}


export type OrderInfo = {
    orderId: string
    order_number: string
    email: string
    id: string
    line_items: Array<LineItem>
    note: string
    timestamp: string
    token: string
    updated_at: string
}

export const fetchAllLJLSupportersList = async (): Promise<Array<OrderInfo>> => {
    const authHeader = { Authorization: `Bearer ${localStorage.getItem(ID_TOKEN_KEY)}` }
    const response = await fetch(fetchEndpoint, { method: "GET", headers: authHeader })
    if (!response.ok) throw new Error(`Failed to fetch supporters: ${response.statusText}`)
    const result = await response.json() as { data: Array<OrderInfo> }
    return result.data;
};