import React from "react"

import { faAnglesLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Link } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Link as ReactRouterDomLink } from "react-router-dom"

import DataCollectionSidebarEntries from "../../DataCollection/SidebarEntries"
import ScoutingSidebarEntries from "../../Scouting/SidebarEntries"
import {
  StyleDrawer,
  StyleList,
  StyleListItem,
  StyleListItemIcon,
  StyleListItemText,
} from "../../StyleMaterialUI"
import TaiyoroSidebarEntries from "../../Taiyoro/SidebarEntries"
import UserManagementSidebarEntries from "../../UserManagement/SidebarEntries"
import { StyleImg, StyleNavDivider, NavBox } from "./styles"
import FutarokuSidebarEntries from "components/Futaroku/SidebarEntries"

interface Props {
  open: boolean
  onClose: () => void
}

const Sidebar = ({ open, onClose }: Props) => {
  const { t } = useTranslation()

  return (
    <StyleDrawer
      className="drawer"
      variant="persistent"
      anchor="left"
      open={open}
    >
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <StyleList>
          <StyleListItem onClick={onClose}>
            <StyleListItemIcon>
              <FontAwesomeIcon
                icon={faAnglesLeft}
                size="sm"
                fixedWidth
              />
            </StyleListItemIcon>
            <StyleListItemText primary={t("closeSidebar")} />
          </StyleListItem>
        </StyleList>
      </Box>
      <NavBox>
        <Link
          to="/"
          component={ReactRouterDomLink}
        >
          <StyleImg
            height="40px"
            src="/images/playbrain-logo.png"
          />
        </Link>
      </NavBox>
      <StyleNavDivider />
      <Box
        height="calc(100% - 130px)"
        sx={{ overflowY: "auto" }}
      >
        <StyleList>
          <FutarokuSidebarEntries />
          <TaiyoroSidebarEntries />
          <UserManagementSidebarEntries />
          <ScoutingSidebarEntries />
          <DataCollectionSidebarEntries />
        </StyleList>
      </Box>
    </StyleDrawer>
  )
}

export default Sidebar
