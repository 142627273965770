import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { fetchAllLJLSupportersList, type OrderInfo } from 'services/Futaroku'
import { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { styled } from "@mui/material/styles"
import * as XLSX from 'xlsx'


const StyledLineItemBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.5),
    '& .supporter-property': {
        paddingLeft: theme.spacing(2)
    }
}))

const StyledDownloadButton = styled(Button)(({ theme }) => ({
    marginLeft: "auto",
    display: "flex",
    marginBottom: theme.spacing(1)
}))

const LJLSupporters = () => {
    const [supporters, setSupporters] = useState<Array<OrderInfo>>([])
    const [isMounted, setIsMounted] = useState(true)

    const downloadExcel = () => {
        const flattenedSupporters = supporters.flatMap((item) =>
            item.line_items.map((lineItem) => ({
                OrderId: item.order_number,
                email: item.email,
                timestamp: item.timestamp,
                title: lineItem.title,
                vendor: lineItem.vendor,
                supporterNames: lineItem.properties
                    ? lineItem.properties.map(({ name, value }) => value).join(', ')
                    : '',
            }))
        )

        const worksheet = XLSX.utils.json_to_sheet(flattenedSupporters)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        XLSX.writeFile(workbook, "SupportersOrderList.xlsx")
    }

    useEffect(() => {
        setIsMounted(true)

        const fetchSupporters = async () => {
            try {
                const supporterList = await fetchAllLJLSupportersList()

                const filteredSupporters = supporterList.filter((row) =>
                    row.line_items.some(item => item.properties && Object.keys(item.properties).length > 0)
                )

                if (isMounted) setSupporters(filteredSupporters)
            } catch (error) {
                console.error('Error fetching supporters:', error)
            }
        }

        void fetchSupporters()
        return () => setIsMounted(false)
    }, [])

    return (
        <Box>
            <StyledDownloadButton onClick={() => downloadExcel()}>
                Download As Excel
            </StyledDownloadButton>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Order ID</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Line Items</TableCell>
                            <TableCell>Timestamp</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {
                            supporters.map((row) => (
                                <TableRow
                                    key={row.order_number}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{row.order_number}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>
                                        <Box key={row.order_number}>
                                            {row.line_items.map((item, index) => (
                                                <StyledLineItemBox key={index}>
                                                    <Typography><strong>Title:</strong> {item.title}</Typography>
                                                    <Typography><strong>Vendor:</strong> {item.vendor}</Typography>
                                                    <Typography><strong>Properties:</strong>
                                                        {item.properties?.map((property, index) => (
                                                            <Typography className="supporter-property" key={index}>
                                                                <strong>{property.name}:</strong> {property.value}
                                                            </Typography>
                                                        ))}
                                                    </Typography>
                                                </StyledLineItemBox>
                                            ))}
                                        </Box>
                                    </TableCell>

                                    <TableCell>{new Date(row.timestamp).toLocaleString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</TableCell>
                                </TableRow>
                            ))
                        }

                        {supporters.length === 0 && (
                            <Box>
                                <StyledLineItemBox>
                                    No Records Found
                                </StyledLineItemBox>
                            </Box>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>

        </Box >
    )
}

export default LJLSupporters
