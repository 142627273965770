
import {
  faList,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ListItemIcon } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLocation, matchPath } from "react-router"
import { StyleNavLinkButton } from "../../PageLayout/Sidebar/styles"
import { StyleListItem, StyleListItemText } from "../../StyleMaterialUI"

const FutarokuSidebarEntries = () => {
  const { t } = useTranslation(["futaroku"])
  const location = useLocation()

  const isFutarokuRoute = matchPath(location.pathname, {
    path: "/futaroku",
  })

  return (
    <>
      {isFutarokuRoute && (
        <>
          <StyleNavLinkButton
            exact
            to="/futaroku/ljl-supporters"
          >
            <StyleListItem selected={location.pathname === "/futaroku/ljl-supporters"}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faList} />
              </ListItemIcon>
              <StyleListItemText primary={t("list.pageTitle")} />
            </StyleListItem>
          </StyleNavLinkButton>
        </>
      )}
    </>
  )
}

export default FutarokuSidebarEntries
